import React from 'react';
import ProductCardAlt from 'components/Card/ProductAlt';
import CategorySignup from 'components/Card/CategorySignup';
import LoadMore from 'components/LoadMore';
import * as DatahubService from 'utils/datahub';
import { EVENT_ACTIONS, CLICK_SOURCES } from 'utils/datahub.constants';
import Cookie from 'js-cookie';
import { v4 as uuid } from 'uuid';
import config from '../../../../env.config';

function ProductsList(props) {
  const {
    showSideFilter,
    showBuyButton,
    onProductClick,
    onProductBuyButtonClick,
    productIdPrefix,
    priceLoading,
    seasonInfo,
    origin,
    searchId,
    locale
  } = props;
  const { isMobile } = props;
  const gridProps = { xs: 6, sm: 6, md: 6, lg: 4, xl: 3 };
  let totalProductsPerPage = config.envConfig.RESULTS.QTY_PER_PAGE;

  if (props.isCreatorList) {
    totalProductsPerPage = config.envConfig.PRODUCTS.QTY_WELL_REVIEWED;
  }

  if (!showSideFilter) {
    gridProps.md = 4;
    gridProps.xs = 6;
    gridProps.lg = 3;
  }

  const sendDatahubEvent = (product, index) => {
    const productPosition = index;

    DatahubService.sendEvent({
      action: EVENT_ACTIONS.CLICK,
      sessionId: Cookie.get('sid'),
      productId: product?.productId,
      searchId,
      source: CLICK_SOURCES.RESULTS,
      origin,
      productPosition
    });
  };

  const sendOptimizelyEvent = product => {
    window['optimizely'] = window['optimizely'] || [];
    window['optimizely'].push({
      type: 'event',
      eventName: 'click-product-card-category-page',
      tags: {
        value: `${product.title}: Card Category List`
      }
    });
  };

  return (
    <>
      {props?.products.map((product, index) => {
        if (locale === 'pt-br' && index === 3) {
          return (
            <>
              <CategorySignup key={uuid()} />
              <ProductCardAlt
                key={product?.productId || index}
                idPrefix={productIdPrefix}
                product={product}
                onClick={() => {
                  onProductClick(product);
                  sendDatahubEvent(product, index);
                  sendOptimizelyEvent(product);
                }}
                onBuyButtonClick={() => onProductBuyButtonClick(product)}
                showBuyButton={showBuyButton}
                /* @ts-expect-error */
                isMobile={isMobile}
                showPrice
                price={product?.price}
                discountPrice={product?.discountPrice}
                priceLoading={priceLoading}
                campaignInfo={seasonInfo}
                searchId={searchId}
                origin={origin}
                firstImagePriority={index < 4}
              />
            </>
          );
        }

        return (
          <ProductCardAlt
            key={product?.productId || index}
            idPrefix={productIdPrefix}
            product={product}
            onClick={() => {
              onProductClick(product);
              sendDatahubEvent(product, index);
              sendOptimizelyEvent(product);
            }}
            onBuyButtonClick={() => onProductBuyButtonClick(product)}
            showBuyButton={showBuyButton}
            /* @ts-expect-error */
            isMobile={isMobile}
            showPrice
            price={product?.price}
            discountPrice={product?.discountPrice}
            priceLoading={priceLoading}
            campaignInfo={seasonInfo}
            searchId={searchId}
            origin={origin}
            firstImagePriority={index < 4}
          />
        );
      })}

      {props.totalProducts > totalProductsPerPage && (
        <LoadMore
          onClick={props.loadMoreClick}
          seenProducts={props.products.length}
          totalProducts={props.totalProducts}
        />
      )}
    </>
  );
}

export default ProductsList;
